import { ArrowRightIcon, ChevronRightIcon, StarIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logo from './logo.png'
import bg from './bg.png'

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    ArrowPathIcon,
    Bars3Icon,
    BookmarkSquareIcon,
    CalendarIcon,
    ChartBarIcon,
    ClockIcon,
    CurrencyPoundIcon,
    CursorArrowRaysIcon,
    LifebuoyIcon,
    LockClosedIcon,
    PhoneIcon,
    PlayIcon,
    ShieldCheckIcon,
    Squares2X2Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

export default function Header() {

    const token = localStorage.getItem("token")
    const userType = JSON.parse(localStorage.getItem("user"))?.type;

    return <Popover className="relative bg-fac-blue-500 shadow text-fac-yellow-500">
        <div className="mx-auto max-w-7xl px-4 sm:px-6">
            <div className="flex items-center justify-between py-2 md:justify-start md:space-x-10">
                <div className="flex justify-start lg:w-0 lg:flex-1">
                    <Link to='/'>
                        <img
                            className="h-12 w-auto sm:h-16"
                            src={logo}
                            alt=""
                        />
                    </Link>
                </div>
                <div className="-my-2 -mr-2 md:hidden">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-fac-yellow-500">
                        <span className="sr-only">Open menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                </div>
                <Popover.Group as="nav" className="hidden space-x-10 md:flex">

                    <Link to='/' className="text-base font-medium text-fac-yellow-500 hover:text-gray-900">
                        Home
                    </Link>
                    <Link to='/creches' className="text-base font-medium text-fac-yellow-500 hover:text-gray-900">
                        Creches
                    </Link>
                    <Link to='/jobs' className="text-base font-medium text-fac-yellow-500 hover:text-gray-900">
                        Jobs
                    </Link>

                </Popover.Group>
                {!token && <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
                    <Link to='/login' className="ml-8 whitespace-nowrap text-base font-medium text-fac-yellow-500 hover:text-gray-900">
                        Sign in
                    </Link>
                    <Link to='/register'
                        className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-fac-yellow-600 px-4 py-2 text-base font-medium text-gray-900 shadow-sm hover:bg-fac-yellow-700"
                    >
                        Sign up
                    </Link>
                </div>}
                {userType == 0 && <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
                    <Link to='/creches' className="whitespace-nowrap text-base font-medium text-fac-yellow-500 hover:text-gray-900">
                        Search
                    </Link>
                    <Link to='/bookings' className="ml-8 whitespace-nowrap text-base font-medium text-fac-yellow-500 hover:text-gray-900">
                        Your Bookings
                    </Link>
                </div>}
                {userType == 1 && <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
                    <Link to='/creche-manage' className="whitespace-nowrap text-base font-medium text-fac-yellow-500 hover:text-gray-900">
                        Manage Creche
                    </Link>
                </div>}
            </div>
        </div>

        <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
        >
            <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
            >
                <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">

                    <div className="space-y-6 py-6 px-5">
                        <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                            <Link to='/' className="text-base font-medium text-gray-900 hover:text-gray-700">
                                Home
                            </Link>

                            <Link to='/creches' className="text-base font-medium text-gray-900 hover:text-gray-700">
                                Creches
                            </Link>

                            <Link to='/jobs' className="text-base font-medium text-gray-900 hover:text-gray-700">
                                Jobs
                            </Link>

                        </div>
                        <div>
                            <Link to='/login'
                                className="flex w-full items-center justify-center rounded-md border border-transparent bg-fac-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-fac-yellow-700"
                            >
                                Sign up
                            </Link>
                            <p className="mt-6 text-center text-base font-medium text-gray-500">
                                Existing customer?
                                <Link to='/login' className="text-fac-yellow-600 hover:text-fac-yellow-500">
                                    Sign in
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </Popover.Panel>
        </Transition>
    </Popover>
}