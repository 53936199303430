import { useEffect, useState } from "react";
import { api } from "./api.service";
import Header from "./header"
import { IKImage, IKContext, IKUpload } from 'imagekitio-react'
import { Link, useParams } from "react-router-dom";
import { StarIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { RadioGroup } from '@headlessui/react'
import { CalendarIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ChatBubbleBottomCenterIcon, CheckIcon } from '@heroicons/react/24/outline'
import Footer from "./footer";
import ReactStars from 'react-stars'

export default function Creche() {

    const [creche, setCreche] = useState({});
    const params = useParams();

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/public/creche/${params.id}`).then(x => {
            setCreche(x.creche)
        });
    }, [])

    const reload = () => {
        api(`${process.env.REACT_APP_API_URL}/public/creche/${params.id}`).then(x => {
            setCreche(x.creche)
        });
    }

    return (<>
        <Header></Header>
        <Product reload={reload} creche={creche}></Product>
        <Footer></Footer>
    </>

    )
}

function Product({ creche, reload }) {
    const [sessionId, setSessionId] = useState(null)
    const [open, setOpen] = useState(false)

    const bookSession = (id, data) => {
        setSessionId(null)

        api(`${process.env.REACT_APP_API_URL}/booking/make-booking`, { data: { sessionId: id, details: data } }).then(x => {
            window.location.replace(x.url)
        });
    }
    const submitReview = (data) => {
        setOpen(false)

        api(`${process.env.REACT_APP_API_URL}/review/${creche._id}`, { data: data }).then(x => {
            reload()
        });
    }


    const openSession = (id) => {
        api(`${process.env.REACT_APP_API_URL}/public/creche/${creche._id}`).then(x => {
            setSessionId(id)
        });
    }

    return (
        <div className="bg-white">
            <Messages id={creche._id} name={creche.name}></Messages>
            {!!sessionId && <PurchaseModal open={!!sessionId} setOpen={() => setSessionId(null)} id={sessionId} sessionData={creche?.sessions?.find(x => x._id == sessionId)} book={(id, data) => bookSession(id, data)}></PurchaseModal>}
            <ReviewModal open={open} setOpen={setOpen} submit={(data) => submitReview(data)}></ReviewModal>
            <div className="pt-6">

                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
                    <div className="hidden overflow-hidden rounded-lg lg:block">
                        <IKContext
                            urlEndpoint="https://ik.imagekit.io/verticode">
                            <IKImage className="h-full w-full object-cover object-center" path={`${creche.image_id}`} />
                        </IKContext>
                    </div>
                </div>

                <div className="mx-auto max-w-2xl px-4 pt-10 pb-6 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-4 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-8">
                    <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{creche.name}</h1>
                    </div>

                    <div className="mt-4 lg:row-span-3 lg:col-span-2 lg:mt-0">
                        <p className="text-3xl tracking-tight text-gray-900 mb-5">Upcoming Available Sessions</p>
                        <List sessions={creche.sessions} openSession={(id) => { openSession(id); }}></List>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pt-6 lg:pb-16 lg:pr-8">
                        <div>
                            <div className="space-y-6">
                                <p className="text-base text-gray-900 mb-3">{creche.about}</p>
                                <p className="text-base text-gray-900"><b>Age Range - </b>{creche.age}</p>
                                <p className="text-base text-gray-900"><b>Phone - </b>{creche.contact}</p>
                                <p className="text-base text-gray-900"><b>Email - </b>{creche.contactEmail}</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="mx-auto max-w-2xl px-4 pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl ">
                    <div className="flex justify-between">
                        <div className="flex space-x-6">
                            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl my-auto">Reviews - </h1>
                            <ReactStars value={(creche.reviews?.reduce((a, b) => a + b.stars, 0) / creche.reviews?.length)} edit={false} size={40} color2={'#ffd700'} />

                        </div>

                        <div className="float-right cursor-pointer hover:underline" onClick={() => setOpen(true)}>Leave Review</div>
                    </div>
                    <div>
                        {creche.reviews?.map(x => <div className="my-3">
                            <ReactStars value={x.stars} half={false} edit={false} size={30} color2={'#ffd700'} />
                            <div className="text-sm text-neutral-600">{x.name}</div>
                            <div className="my-1">{x.review}</div>
                        </div>)}
                    </div>

                </div>
            </div>
        </div>
    )
}

function List({ sessions, openSession }) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return (
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
                {sessions?.filter(x => !x.isBooked && (new Date(x.date) >= today)).sort(function (a, b) {
                    return new Date(a.date) - new Date(b.date);
                }).map((session) => (
                    <li key={session._id}>
                        <span onClick={() => openSession(session._id)} className="block hover:bg-gray-50">
                            <div className="flex items-center px-4 py-4 sm:px-6">
                                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                    <div className="truncate">
                                        <div className="flex text-sm">
                                            <p className="truncate font-medium text-fac-blue-600">{session.title}</p>
                                            <p className="ml-1 flex-shrink-0 font-normal text-gray-500">£{session.price}</p>
                                        </div>
                                        <div className="mt-2 flex">
                                            <div className="flex items-center text-sm text-gray-500">
                                                <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                                <p>
                                                    {session.date ? session.date.split('T')[0] : ''}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ml-5 flex-shrink-0">
                                    <span>Book Now</span>
                                    <ChevronRightIcon className="h-5 w-5 inline text-gray-400" aria-hidden="true" />
                                </div>
                            </div>
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    )
}

function PurchaseModal({ open, setOpen, id, sessionData, book }) {

    const [values, setValues] = useState({});

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Book This Session
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {sessionData.title}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                {sessionData.date ? sessionData.date.split('T')[0] : ''} {sessionData.timeRange}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                {sessionData.age}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                {sessionData.notes}
                                            </p>
                                        </div>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Enter your child's information below.
                                            </p>
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Child's Name <span className="text-red-500">*</span>
                                            </label>
                                            <div className="mt-1 w-full">
                                                <input
                                                    value={values.childsName}
                                                    onChange={(e) => setValues({ ...values, childsName: e.target.value })}
                                                    type="text"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Child's Age <span className="text-red-500">*</span>
                                            </label>
                                            <div className="mt-1 w-full">
                                                <input
                                                    value={values.childsAge}
                                                    onChange={(e) => setValues({ ...values, childsAge: e.target.value })}
                                                    type="number"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Medical Notes
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <textarea
                                                    value={values.medicalNotes}
                                                    onChange={(e) => setValues({ ...values, medicalNotes: e.target.value })}
                                                    rows={3}
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Emergency Contact Details <span className="text-red-500">*</span>
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <textarea
                                                    value={values.contactDetails}
                                                    onChange={(e) => setValues({ ...values, contactDetails: e.target.value })}
                                                    rows={3}
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Doctor's Info
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <textarea
                                                    value={values.doctorsInfo}
                                                    onChange={(e) => setValues({ ...values, doctorsInfo: e.target.value })}
                                                    rows={3}
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Notes
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <textarea
                                                    value={values.notes}
                                                    onChange={(e) => setValues({ ...values, notes: e.target.value })}
                                                    rows={3}
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <i className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 text-center">Find A Creche charge a 15% administration fee.</i> */}
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        disabled={!values.childsName || !values.childsAge || !values.contactDetails}
                                        type="button"
                                        className="disabled:opacity-30 disabled:pointer-events-none flex mx-auto justify-center rounded-md border border-transparent bg-fac-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-fac-blue-700 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2 sm:text-sm"
                                        onClick={() => book(id, values)}
                                    >
                                        Book Now
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
function ReviewModal({ open, setOpen, submit }) {

    const [values, setValues] = useState({});

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Leave a Review
                                        </Dialog.Title>

                                        <ReactStars value={values.stars} half={false} onChange={(e) => { setValues({ ...values, stars: e }); console.log(e) }} size={40} color2={'#ffd700'} />

                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Review
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <textarea
                                                    value={values.review}
                                                    onChange={(e) => setValues({ ...values, review: e.target.value })}
                                                    rows={3}
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        disabled={!values.stars || !values.review}
                                        type="button"
                                        className="disabled:opacity-30 disabled:pointer-events-none flex mx-auto justify-center rounded-md border border-transparent bg-fac-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-fac-blue-700 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2 sm:text-sm"
                                        onClick={() => { submit(values); setValues({}) }}
                                    >
                                        Submit Review
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function Messages({ id, name }) {

    const [open, setOpen] = useState(false)
    const [messages, setMessages] = useState([])
    const [value, setValue] = useState();
    
    const sendMessage = () => {
        api(`${process.env.REACT_APP_API_URL}/message/${id}`, { message: value }).then(x => {
            setValue('')
            api(`${process.env.REACT_APP_API_URL}/message/${id}`).then(x => {
                setMessages(x.messages)
            });
        });
    }

    useEffect(() => {
        if (id) {
            api(`${process.env.REACT_APP_API_URL}/message/${id}`).then(x => {
                setMessages(x.messages)
            });
        }
    }, [id])

    if (open) return <>
        <div className="fixed bottom-5 right-5 bg-white rounded shadow overflow-hidden ">
            <div className="bg-blue-500 p-2 flex shadow">
                <div className="flex-1 text-xl text-white pt-1">{name}</div>
                <div onClick={() => setOpen(false)}><XMarkIcon className="w-8 cursor-pointer text-neutral-100 hover:text-neutral-300"></XMarkIcon></div>
            </div>
            <div className="flex flex-col h-96 overflow-y-auto w-96 p-3">
                {messages?.map(x => <div className={`my-2 ${x.sentByCreche ? '' : 'text-right'}`}>
                    <div className="text-xs text-neutral-600"><b>{x.sentByCreche ? name : x.user?.name}</b> {new Date(x.date).toDateString()}</div>
                    <div>{x.message}</div>
                </div>)}
            </div>

            <div className="flex border-t p-3 align-baseline">
                <textarea rows="3" className="flex-1 rounded-lg shadow h-20 p-1" value={value} onChange={(e) => setValue(e.target.value)}></textarea>

                <div className="flex flex-col justify-end">
                    <div className="w-20 text-center bg-blue-500 text-white rounded ml-5 cursor-pointer py-2" onClick={() => sendMessage()}>Send</div>
                </div>
            </div>
        </div>
    </>
    return <div onClick={() => setOpen(true)} className="fixed bottom-5 right-5 cursor-pointer bg-blue-500 rounded-full py-2 px-4 shadow text-white text-lg font-medium">
        <ChatBubbleBottomCenterIcon className="w-8 text-white inline"></ChatBubbleBottomCenterIcon> Chat With Creche
    </div>
}