import { IKContext, IKImage } from 'imagekitio-react';
import { useEffect, useState } from "react";
import DatePicker from 'react-date-picker';
import { Link } from "react-router-dom";
import { api } from "./api.service";
import Footer from "./footer";
import Header from "./header";

const compareDates = (d1, d2) => {
    let date1 = new Date(d1);
    date1.setHours(0, 0, 0, 0)

    let date2 = new Date(d2);
    date2.setHours(0, 0, 0, 0)
    return date1 + '' == date2 + ''
}

export default function Creches() {

    const [creches, setCreches] = useState([]);

    const [date, setDate] = useState()

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/public/creches`).then(x => {
            setCreches(x.creches)
        });
    }, [])

    return (<>
        <Header></Header>
        <div className="bg-white">
            <Title></Title>
            <div className="max-w-2xl px-4 py-8 mx-auto sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">

                <div className="mb-10">
                    <DatePicker minDate={new Date()} onChange={(e) => setDate(e)} value={date} />
                </div>

                <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                    {creches.filter(x => x.sessions.find(s => !s.isBooked && (!date || compareDates(s.date, date)))).map((creche) => (
                        <Link key={creche._id} to={'/creche/' + creche._id} className="group">
                            <div className="w-full overflow-hidden bg-gray-200 rounded-lg aspect-w-1 aspect-h-1 xl:aspect-w-7 xl:aspect-h-8">
                                <IKContext
                                    urlEndpoint="https://ik.imagekit.io/verticode">
                                    <IKImage className="object-cover object-center w-full h-full group-hover:opacity-75" path={`${creche.image_id}`} />
                                </IKContext>
                            </div>
                            <h3 className="mt-4 text-sm text-gray-700">{creche.name}</h3>
                            <p className="mt-1 text-lg font-medium text-gray-900">{creche.about}</p>
                        </Link>
                    ))}
                </div>
            </div>
        </div>

        <Footer></Footer>
    </>

    )
}

function Title() {
    return (
        <div className="bg-white">
            <div className="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-lg font-semibold text-fac-blue-300">Creches</h2>
                    <p className="mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
                    Sign in and browse available childcare options 
                    </p>
                    <p className="max-w-xl mx-auto mt-5 text-xl text-gray-500">
                    If you have any questions, get in touch with us at <a className="underline" href="mailto:info@findacreche.com">info@findacreche.com</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

