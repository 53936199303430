import { CalendarIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "./api.service";
import Footer from "./footer";
import Header from "./header";

export default function Account() {

    const [bookings, setBookings] = useState([]);
    const [creches, setCreches] = useState([]);
    const params = useParams();

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/booking`).then(x => {
            console.log(x.bookings)
            setBookings(x.bookings)
        });

        api(`${process.env.REACT_APP_API_URL}/creche`).then(x => {
            setCreches(x.creches)
        });
    }, [])

    return (<>
        <Header></Header>
        <Title></Title>
        <List bookings={bookings} creches={creches}></List>
        <Footer></Footer>
    </>

    )
}


function Title() {
    return (
        <div className="bg-white">
            <div className="px-4 py-16 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-lg font-semibold text-fac-blue-300">Find a Creche</h2>
                    <p className="mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
                    Bookings
                    </p>
                    <p className="max-w-xl mx-auto mt-5 text-xl text-gray-500">
                    If you need to make changes to your booking, please contact the Creche directly. Creche contact details & chat function can be accessed on the Creche profile. If that doesn't work, you can reach the Find A Creche team at <a className="underline" href="mailto:info@findacreche.com"></a>
                    </p>
                </div>
            </div>
        </div>
    )
}

function List({ bookings, creches }) {
    return (
        <div className="bg-white">
            <div className="max-w-6xl mx-auto overflow-hidden bg-gray-100 shadow sm:rounded-md">
                <ul role="list" className="divide-y divide-gray-300">
                    {bookings?.filter(x => x.paymentStatus == 1).map((booking) => (
                        <li key={booking._id}>
                            <span className="block hover:bg-gray-50">
                                <div className="flex items-center px-4 py-4 sm:px-6">
                                    <div className="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
                                        <div className="truncate">
                                            <div className="flex text-sm">
                                                <p className="mb-3 text-2xl truncate text-fac-blue-600">{creches.find(x => x._id == booking.session.creche)?.name}</p>
                                            </div>
                                            <div className="flex text-sm">
                                                <p className="font-medium truncate text-fac-blue-600">{creches.find(x => x._id == booking.session.creche)?.about}</p>
                                            </div>
                                            <div className="flex text-sm">
                                                <p className="font-medium truncate text-fac-blue-600">{creches.find(x => x._id == booking.session.creche)?.contact}</p>
                                            </div>
                                            <div className="flex text-sm">
                                                <p className="font-medium truncate text-fac-blue-600">{creches.find(x => x._id == booking.session.creche)?.city} {creches.find(x => x._id == booking.session.creche)?.postcode}</p>
                                                <p className="flex-shrink-0 ml-1 font-normal text-gray-500">{creches.find(x => x._id == booking.session.creche)?.addressLine1}</p>
                                                <p className="flex-shrink-0 ml-1 font-normal text-gray-500">{creches.find(x => x._id == booking.session.creche)?.addressLine2}</p>
                                            </div>
                                            <div className="flex mt-3 text-sm">
                                                <p className="font-medium truncate text-fac-blue-600">{booking.session.title}</p>
                                                <p className="flex-shrink-0 ml-1 font-normal text-gray-500">£{booking.session.price}</p>
                                            </div>
                                            <div className="flex mt-2">
                                                <div className="flex items-center text-sm text-gray-500">
                                                    <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                                    <p>
                                                        {booking.session.date ? booking.session.date.split('T')[0] : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>

    )
}