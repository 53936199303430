import { StarIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom';
import bg from './bg.png'
import bg2 from './bg2.jpg'
import bg3 from './bg3.jpg'
import { InboxIcon } from '@heroicons/react/24/outline'
import {
    ClockIcon,
    CurrencyPoundIcon,
    LockClosedIcon,
} from '@heroicons/react/24/outline'
import Header from './header';
import Footer from './footer';

export default function Success() {
    return (
        <div className="relative bg-gray-100">
            <Header></Header>
            <div className="bg-fac-blue-500 py-24 sm:py-32 lg:py-40">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="sm:text-center">
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl">Payment Successful</p>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-50 sm:text-2xl">You will receive payment confirmation via email</p>
                    </div>

                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}
