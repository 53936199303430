import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    Bars3CenterLeftIcon,
    BellIcon,
    ChatBubbleBottomCenterIcon,
    ClockIcon,
    CogIcon,
    CreditCardIcon,
    DocumentChartBarIcon,
    HomeIcon,
    QuestionMarkCircleIcon,
    ScaleIcon,
    ShieldCheckIcon,
    UserGroupIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import {
    ArrowDownIcon,
    BanknotesIcon,
    BuildingOfficeIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    MagnifyingGlassIcon,
    UserCircleIcon,

} from '@heroicons/react/20/solid'
import { Link, useNavigate } from 'react-router-dom'
import { api } from './api.service'
import { IKImage, IKContext, IKUpload } from 'imagekitio-react'

const navigation = [
    { name: 'Dashboard', href: '/creche-manage', icon: HomeIcon, current: false },
    { name: 'Settings', href: '/creche-settings', icon: CogIcon, current: true },
    { name: 'Messages', href: '/creche-messages', icon: ChatBubbleBottomCenterIcon, current: false }
]
const secondaryNavigation = [
    { name: 'Logout', href: '/logout', icon: ArrowDownIcon }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CrecheSettings() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const navigate = useNavigate();

    const [values, setValues] = useState({});

    useEffect(() => {

        api(`${process.env.REACT_APP_API_URL}/creche/settings`).then(x => {
            setValues(x)
        });

    }, [])

    const submit = () => {
        api(`${process.env.REACT_APP_API_URL}/creche/settings`, { creche: values }).then(x => {
            navigate('/creche-manage')
        });
    }

    const onSuccess = (res) => {
        setValues({ ...values, image_id: res.filePath });
    };


    return (
        <div className="min-h-full">
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-fac-blue-400 pt-5 pb-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <nav
                                    className="mt-5 h-full flex-shrink-0 divide-y divide-fac-blue-500 overflow-y-auto"
                                    aria-label="Sidebar"
                                >
                                    <div className="space-y-1 px-2">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-fac-blue-500 text-white'
                                                        : 'text-fac-blue-100 hover:text-white hover:bg-fac-blue-600',
                                                    'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-fac-blue-200" aria-hidden="true" />
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="mt-6 pt-6">
                                        <div className="space-y-1 px-2">
                                            {secondaryNavigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-fac-blue-100 hover:bg-fac-blue-600 hover:text-white"
                                                >
                                                    <item.icon className="mr-4 h-6 w-6 text-fac-blue-200" aria-hidden="true" />
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </nav>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-grow flex-col overflow-y-auto bg-fac-blue-400 pt-5 pb-4">
                    <nav className="mt-5 flex flex-1 flex-col divide-y divide-fac-blue-500 overflow-y-auto" aria-label="Sidebar">
                        <div className="space-y-1 px-2">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                        item.current ? 'bg-fac-blue-500 text-white' : 'text-fac-blue-100 hover:text-white hover:bg-fac-blue-600',
                                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-fac-blue-200" aria-hidden="true" />
                                    {item.name}
                                </a>
                            ))}
                        </div>
                        <div className="mt-6 pt-6">
                            <div className="space-y-1 px-2">
                                {secondaryNavigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-fac-blue-100 hover:bg-fac-blue-600 hover:text-white"
                                    >
                                        <item.icon className="mr-4 h-6 w-6 text-fac-blue-200" aria-hidden="true" />
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

            <div className="flex flex-1 flex-col lg:pl-64">
                <div className="flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none">
                    <button
                        type="button"
                        className="border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-fac-blue-500 lg:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    {/* Search bar */}
                    <div className="flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                        <div className="flex flex-1">

                        </div>
                        <div className="ml-4 flex items-center md:ml-6">


                        </div>
                    </div>
                </div>
                <main className="flex-1 pb-8 p-10">


                    <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="space-y-6 sm:space-y-5">
                                <div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Creche</h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        Edit information about your Creche
                                    </p>
                                </div>

                                <div className="space-y-6 sm:space-y-5">
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Creche Name
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={values.name}
                                                onChange={(e) => setValues({ ...values, name: e.target.value })}
                                                type="text"
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Age Range
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={values.ageRange}
                                                onChange={(e) => setValues({ ...values, ageRange: e.target.value })}
                                                type="text"
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            About Your Creche
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <textarea
                                                value={values.about}
                                                onChange={(e) => setValues({ ...values, about: e.target.value })}
                                                rows={3}
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                            />
                                            <p className="mt-2 text-sm text-gray-500">This will be displayed on your Creche profile page</p>
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Contact Phone Number
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={values.contact}
                                                onChange={(e) => setValues({ ...values, contact: e.target.value })}
                                                type="text"
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Contact Email
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={values.contactEmail}
                                                onChange={(e) => setValues({ ...values, contactEmail: e.target.value })}
                                                type="text"
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Address Line 1
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={values.addressLine1}
                                                onChange={(e) => setValues({ ...values, addressLine1: e.target.value })}
                                                type="text"
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Address Line 2
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={values.addressLine2}
                                                onChange={(e) => setValues({ ...values, addressLine2: e.target.value })}
                                                type="text"
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            City
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={values.city}
                                                onChange={(e) => setValues({ ...values, city: e.target.value })}
                                                type="text"
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Postal code
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                value={values.postcode}
                                                onChange={(e) => setValues({ ...values, postcode: e.target.value })}
                                                type="text"
                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Image
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <IKContext
                                                publicKey="public_EoJWOyxRHK2FkPaJg0Y7alzsi2w="
                                                urlEndpoint="https://ik.imagekit.io/verticode"
                                                authenticationEndpoint="https://tak-api.verticode.co.uk/api/public/image-upload-auth"
                                            >
                                                <IKUpload
                                                    onSuccess={onSuccess}
                                                />
                                            </IKContext>
                                            <IKContext
                                                urlEndpoint="https://ik.imagekit.io/verticode">
                                                <IKImage className="user-logo" path={`${values.image_id}`} width="100" />
                                            </IKContext>
                                        </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                        <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                            Make Creche Live
                                        </label>
                                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <input
                                                checked={values.published}
                                                onChange={(e) => setValues({ ...values, published: e.target.checked })}
                                                type="checkbox"
                                                className="block w-10 h-10 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:max-w-xs sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="pt-5">
                            <div className="flex justify-end">
                                <Link to='/creche-manage'
                                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2"
                                >
                                    Cancel
                                </Link>
                                <div
                                    onClick={() => submit()}
                                    className="cursor-pointer ml-3 inline-flex justify-center rounded-md border border-transparent bg-fac-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-fac-blue-700 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2"
                                >
                                    Save
                                </div>
                            </div>
                        </div>
                    </form>
                </main>
            </div>
        </div>
    )
}
