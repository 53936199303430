import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    Bars3CenterLeftIcon,
    BellIcon,
    ChatBubbleBottomCenterIcon,
    ClockIcon,
    CogIcon,
    CreditCardIcon,
    DocumentChartBarIcon,
    HomeIcon,
    QuestionMarkCircleIcon,
    ScaleIcon,
    ShieldCheckIcon,
    UserGroupIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import {
    ArrowDownIcon,
    BanknotesIcon,
    BuildingOfficeIcon,
    CalendarDaysIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    CurrencyPoundIcon,
    InboxIcon,
    MagnifyingGlassIcon,
    UserCircleIcon,

} from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import { api } from './api.service'
import SessionManage from './session-manage'
import JobManage from './job-manage'


const navigation = [
    { name: 'Dashboard', href: '/creche-manage', icon: HomeIcon, current: true },
    { name: 'Settings', href: '/creche-settings', icon: CogIcon, current: false },
    { name: 'Messages', href: '/creche-messages', icon: ChatBubbleBottomCenterIcon, current: false }
]
const secondaryNavigation = [
    { name: 'Logout', href: '/logout', icon: ArrowDownIcon }
]
const cards = [
    { name: 'Account balance', href: '#', icon: ScaleIcon, amount: '$30,659.45' },
    // More items...
]
const transactions = [
    {
        id: 1,
        name: 'Payment to Molly Sanders',
        href: '#',
        amount: '$20,000',
        currency: 'USD',
        status: 'success',
        date: 'July 11, 2020',
        datetime: '2020-07-11',
    },
    // More transactions...
]
const statusStyles = {
    success: 'bg-green-100 text-green-800',
    processing: 'bg-yellow-100 text-yellow-800',
    failed: 'bg-gray-100 text-gray-800',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const getDateString = (d) => {
    return `${new Date(d).getFullYear()}-${new Date(d).getMonth() + 1}-${new Date(d).getDate()}`
}

export default function CrecheManage() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [id, setId] = useState(null)
    const [id2, setId2] = useState(null)

    const [booking, setBooking] = useState(null)
    const [application, setApplication] = useState(null)
    const [session, setSession] = useState(null);
    const [viewModel, setViewModel] = useState({});

    useEffect(() => {

        api(`${process.env.REACT_APP_API_URL}/creche/manage`).then(x => {
            setViewModel(x);
        });

    }, [])

    useEffect(() => {
        if (!open) {
            api(`${process.env.REACT_APP_API_URL}/creche/manage`).then(x => {
                setViewModel(x);
            });

            setId(null)
        }


    }, [open])

    return (
        <div className="min-h-full">
            <SessionModal open={open} setOpen={setOpen} id={id}></SessionModal>
            <JobModal open={open2} setOpen={setOpen2} id={id2}></JobModal>
            <BookingModal open={!!booking} setOpen={() => { setBooking(null); setSession(null) }} booking={booking} session={session}></BookingModal>
            <ApplicationModal open={!!application} setOpen={() => { setApplication(null); setSession(null) }} application={application} applications={viewModel.applications}></ApplicationModal>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-fac-blue-400 pt-5 pb-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <nav
                                    className="mt-5 h-full flex-shrink-0 divide-y divide-fac-blue-500 overflow-y-auto"
                                    aria-label="Sidebar"
                                >
                                    <div className="space-y-1 px-2">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-fac-blue-500 text-white'
                                                        : 'text-fac-blue-100 hover:text-white hover:bg-fac-blue-600',
                                                    'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-fac-blue-200" aria-hidden="true" />
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="mt-6 pt-6">
                                        <div className="space-y-1 px-2">
                                            {secondaryNavigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-fac-blue-100 hover:bg-fac-blue-600 hover:text-white"
                                                >
                                                    <item.icon className="mr-4 h-6 w-6 text-fac-blue-200" aria-hidden="true" />
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </nav>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-grow flex-col overflow-y-auto bg-fac-blue-400 pt-5 pb-4">
                    <nav className="mt-5 flex flex-1 flex-col divide-y divide-fac-blue-500 overflow-y-auto" aria-label="Sidebar">
                        <div className="space-y-1 px-2">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                        item.current ? 'bg-fac-blue-500 text-white' : 'text-fac-blue-100 hover:text-white hover:bg-fac-blue-600',
                                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-fac-blue-200" aria-hidden="true" />
                                    {item.name}
                                </a>
                            ))}
                        </div>
                        <div className="mt-6 pt-6">
                            <div className="space-y-1 px-2">
                                {secondaryNavigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-fac-blue-100 hover:bg-fac-blue-600 hover:text-white"
                                    >
                                        <item.icon className="mr-4 h-6 w-6 text-fac-blue-200" aria-hidden="true" />
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

            <div className="flex flex-1 flex-col lg:pl-64">
                <div className="flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none">
                    <button
                        type="button"
                        className="border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-fac-blue-500 lg:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    {/* Search bar */}
                    <div className="flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                        <div className="flex flex-1">

                        </div>
                        <div className="ml-4 flex items-center md:ml-6">


                        </div>
                    </div>
                </div>
                <main className="flex-1 pb-8">
                    {/* Page header */}
                    <div className="bg-white shadow">
                        <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                            <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                                <div className="min-w-0 flex-1">
                                    {/* Profile */}
                                    <div className="flex items-center">

                                        <div>
                                            <div className="flex items-center">

                                                <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                                                    {viewModel.name}
                                                </h1>
                                            </div>
                                            <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                                                <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                                                    <BuildingOfficeIcon
                                                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                    {viewModel.creche?.name}
                                                </dd>
                                                {viewModel.creche?.published && <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                                                    <CheckCircleIcon
                                                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                                        aria-hidden="true"
                                                    />
                                                    Creche Live
                                                </dd>}
                                                {!viewModel.creche?.published && <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                                                    <XMarkIcon
                                                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
                                                        aria-hidden="true"
                                                    />
                                                    Creche Not Live
                                                </dd>}
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                                    <span onClick={() => navigate('/creche-settings')}
                                        className="cursor-pointer inline-flex items-center rounded-md border border-transparent bg-fac-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-fac-blue-400 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2"
                                    >
                                        Creche Settings
                                    </span>
                                    <span onClick={() => setOpen(true)}
                                        className="cursor-pointer inline-flex items-center rounded-md border border-transparent bg-fac-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-fac-blue-400 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2"
                                    >
                                        Add a new session
                                    </span>
                                    <span onClick={() => setOpen2(true)}
                                        className="cursor-pointer inline-flex items-center rounded-md border border-transparent bg-fac-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-fac-blue-400 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2"
                                    >
                                        Add a new job
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8">
                        <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                            <h2 className="text-lg font-medium leading-6 text-gray-900">Overview</h2>
                            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                                <div className="overflow-hidden rounded-lg bg-white shadow">
                                    <div className="p-5">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                                <InboxIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                            </div>
                                            <div className="ml-5 w-0 flex-1">
                                                <dl>
                                                    <dt className="truncate text-sm font-medium text-gray-500">Total Bookings</dt>
                                                    <dd>
                                                        <div className="text-lg font-medium text-gray-900">{viewModel?.bookings?.length}</div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-5 py-3">
                                        <div className="text-sm">

                                        </div>
                                    </div>
                                </div>
                                <div className="overflow-hidden rounded-lg bg-white shadow">
                                    <div className="p-5">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                                <CurrencyPoundIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                            </div>
                                            <div className="ml-5 w-0 flex-1">
                                                <dl>
                                                    <dt className="truncate text-sm font-medium text-gray-500">Total Revenue</dt>
                                                    <dd>
                                                        <div className="text-lg font-medium text-gray-900">£{viewModel?.creche?.sessions?.filter(session => viewModel.bookings.find(x => x?.session == session?._id))?.reduce((partialSum, a) => partialSum + a.price, 0)}</div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-5 py-3">
                                        <div className="text-sm">

                                        </div>
                                    </div>
                                </div>
                                <div className="overflow-hidden rounded-lg bg-white shadow">
                                    <div className="p-5">
                                        <div className="flex items-center">
                                            <div className="flex-shrink-0">
                                                <CalendarDaysIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                            </div>
                                            <div className="ml-5 w-0 flex-1">
                                                <dl>
                                                    <dt className="truncate text-sm font-medium text-gray-500">Total Unbooked Sessions</dt>
                                                    <dd>
                                                        <div className="text-lg font-medium text-gray-900">{viewModel?.creche?.sessions?.filter(session => !viewModel.bookings.find(x => x?.session == session?._id))?.length ?? 0}</div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-5 py-3">
                                        <div className="text-sm">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                            Sessions
                        </h2>

                        {/* List (smallest breakpoint only) */}
                        <div className="shadow sm:hidden">
                            <ul role="list" className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                                {viewModel.creche?.sessions?.sort(function (a, b) {
                                    return new Date(b.date) - new Date(a.date);
                                }).map((session) => (
                                    <li key={session._id} onClick={() => {
                                        if (viewModel.bookings?.find(x => x?.session == session?._id)) {
                                            setSession(session);
                                            setBooking(viewModel.bookings?.find(x => x?.session == session?._id));
                                        }
                                        else {
                                            setOpen(true); setId(session._id)
                                        }

                                    }}>
                                        <span className="block bg-white px-4 py-4 hover:bg-gray-50">
                                            <span className="flex items-center space-x-4">
                                                <span className="flex flex-1 space-x-2 truncate">
                                                    <span className="flex flex-col truncate text-sm text-gray-500">
                                                        <span className="truncate">{session.title}</span>
                                                        <span>
                                                            <span className="font-medium text-gray-900">£{session.price}</span>
                                                        </span>
                                                        {getDateString(session.date)}<br />
                                                        {viewModel.bookings?.find(x => x?.session == session?._id) ? 'Booked' : 'Not Booked'}
                                                    </span>
                                                </span>
                                                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Table (small breakpoint and up) */}
                        <div className="hidden sm:block">
                            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                                <div className="mt-2 flex flex-col">
                                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Title
                                                    </th>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Price
                                                    </th>
                                                    <th
                                                        className="hidden bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:block"
                                                        scope="col"
                                                    >
                                                        Status
                                                    </th>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Date
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {viewModel.creche?.sessions?.sort(function (a, b) {
                                                    return new Date(b.date) - new Date(a.date);
                                                }).map((session) => (
                                                    <tr onClick={() => {
                                                        if (viewModel.bookings?.find(x => x?.session == session?._id)) {
                                                            setSession(session);
                                                            setBooking(viewModel.bookings?.find(x => x?.session == session?._id));
                                                        }
                                                        else {
                                                            setOpen(true); setId(session._id)
                                                        }

                                                    }} key={session._id} className="bg-white">
                                                        <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            <div className="flex">
                                                                <span className="group inline-flex space-x-2 truncate text-sm">
                                                                    <p className="truncate text-gray-500 group-hover:text-gray-900 underline">
                                                                        {session.title}
                                                                    </p>
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                            <span className="font-medium text-gray-900">£{session.price}</span>
                                                        </td>
                                                        <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block">
                                                            <span
                                                                className={classNames(
                                                                    statusStyles[viewModel.bookings?.find(x => x?.session == session?._id) ? 'success' : 'processing'],
                                                                    'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                                                )}
                                                            >
                                                                {viewModel.bookings?.find(x => x?.session == session?._id) ? 'Booked' : 'Not Booked'}
                                                            </span>
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                            {getDateString(session.date)}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                            Jobs
                        </h2>

                        {/* List (smallest breakpoint only) */}
                        <div className="shadow sm:hidden">
                            <ul role="list" className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                                {viewModel.jobs?.sort(function (a, b) {
                                    return new Date(b.date) - new Date(a.date);
                                }).map((session) => (
                                    <li key={session._id} onClick={() => {
                                        if (viewModel.applications?.find(x => x?.job == session?._id)) {
                                            setApplication(session._id)
                                        }
                                        else {
                                            setOpen2(true); setId2(session._id)
                                        }
                                    }}>
                                        <span className="block bg-white px-4 py-4 hover:bg-gray-50">
                                            <span className="flex items-center space-x-4">
                                                <span className="flex flex-1 space-x-2 truncate">
                                                    <span className="flex flex-col truncate text-sm text-gray-500">
                                                        <span className="truncate">{session.title}</span>
                                                        {getDateString(session.date)}<br />
                                                        {viewModel.applications?.find(x => x?.job == session?._id) ? 'View Applications' : 'No Applications'}
                                                    </span>
                                                </span>
                                                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                            </span>
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Table (small breakpoint and up) */}
                        <div className="hidden sm:block">
                            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                                <div className="mt-2 flex flex-col">
                                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Title
                                                    </th>
                                                    <th
                                                        className="hidden bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:block"
                                                        scope="col"
                                                    >
                                                        Status
                                                    </th>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Date
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {viewModel.jobs?.sort(function (a, b) {

                                                    return new Date(b.date) - new Date(a.date);
                                                }).map((session) => (
                                                    <tr onClick={() => {
                                                        if (viewModel.applications?.find(x => x?.job == session?._id)) {
                                                            setApplication(session)
                                                        }
                                                        else {
                                                            setOpen2(true); setId2(session._id)
                                                        }
                                                    }} key={session._id} className="bg-white">
                                                        <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            <div className="flex">
                                                                <span className="group inline-flex space-x-2 truncate text-sm">
                                                                    <p className="truncate text-gray-500 group-hover:text-gray-900 underline">
                                                                        {session.title}
                                                                    </p>
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block">
                                                            <span
                                                                className={classNames(
                                                                    statusStyles[viewModel.bookings?.find(x => x?.session == session?._id) ? 'success' : 'processing'],
                                                                    'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                                                )}
                                                            >
                                                                {viewModel.applications?.find(x => x?.job == session?._id) ? 'View Applications' : 'No Applications'}
                                                            </span>
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                            {getDateString(session.date)}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}


function SessionModal({ open, setOpen, id }) {

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                                <SessionManage id={id} close={() => setOpen(false)}></SessionManage>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function JobModal({ open, setOpen, id }) {

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                                <JobManage id={id} close={() => setOpen(false)}></JobManage>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function BookingModal({ open, setOpen, session, booking }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                                <div className="text-gray-800 text-2xl mb-5">{session?.title}</div>
                                <div className="text-gray-600 text-lg">Child's Name:</div>
                                <div className="text-gray-700 text-xl mb-3">{booking?.details?.childsName}</div>

                                <div className="text-gray-600 text-lg">Child's Age:</div>
                                <div className="text-gray-700 text-xl mb-3">{booking?.details?.childsAge}</div>

                                <div className="text-gray-600 text-lg">Medical Notes: </div>
                                <div className="text-gray-700 text-xl mb-3">{booking?.details?.medicalNotes}</div>

                                <div className="text-gray-600 text-lg">Emergency Contact Details: </div>
                                <div className="text-gray-700 text-xl mb-3">{booking?.details?.contactDetails}</div>

                                <div className="text-gray-600 text-lg">Doctors Info: </div>
                                <div className="text-gray-700 text-xl mb-3">{booking?.details?.doctorsInfo}</div>

                                <div className="text-gray-600 text-lg">Parent Notes: </div>
                                <div className="text-gray-700 text-xl mb-3">{booking?.details?.notes}</div>

                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="flex mx-auto justify-center rounded-md border border-transparent bg-fac-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-fac-blue-700 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2 sm:text-sm"
                                        onClick={() => setOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function ApplicationModal({ open, setOpen, application, applications }) {

    console.log(application)
    console.log(applications)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">

                                {applications?.filter(x => x.job == application?._id).map(x => <div className="border-b my-2">
                                    <div className="text-gray-600 text-lg">Name:</div>
                                    <div className="text-gray-700 text-xl mb-3">{x?.name}</div>

                                    <div className="text-gray-600 text-lg">Contact Details:</div>
                                    <div className="text-gray-700 text-xl mb-3">{x?.contactDetails}</div>

                                    <div className="text-gray-600 text-lg">Notes: </div>
                                    <div className="text-gray-700 text-xl mb-3">{x?.details}</div>
                                </div>)}

                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="flex mx-auto justify-center rounded-md border border-transparent bg-fac-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-fac-blue-700 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2 sm:text-sm"
                                        onClick={() => setOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}