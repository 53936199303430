import { Dialog, Transition } from '@headlessui/react';
import { StarIcon } from '@heroicons/react/20/solid';
import {
    ClockIcon,
    CurrencyPoundIcon,
    LockClosedIcon
} from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from './api.service';
import bg from './bg.png';
import bg2 from './bg2.jpg';
import Footer from './footer';
import Header from './header';

export default function Example() {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate();
    const submitReview = (data) => api(`${process.env.REACT_APP_API_URL}/public/wait-list`, { data: data }).then(x => setOpen(false));

    return (
        <div className="relative bg-gray-100">
            <Header></Header>

            <main className="lg:relative">
                <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full ">
                    <img
                        className="absolute inset-0 object-cover w-full h-full"
                        src={bg}
                    />
                </div>
                <div className="relative z-10 w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:py-48">
                    <div className="px-4 sm:px-8 xl:pr-16">
                        <h1 className="text-4xl font-bold tracking-tight text-gray-100 sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl">
                            <span className="block xl:inline">FIND A CRECHE WITH </span>{' '}
                            <span className="block text-fac-yellow-600 xl:inline">AVAILABILITY NEAR YOU</span>
                        </h1>
                        <p className="max-w-md mx-auto mt-3 text-lg text-gray-50 sm:text-xl md:mt-5 md:max-w-3xl">
                            Explore and book childcare options
                        </p>
                        <div className="mt-10 sm:flex sm:justify-center lg:justify-center">
                            <div className="rounded-md shadow">
                                <div onClick={() => navigate('/creches')}
                                    className="flex items-center justify-center w-full px-8 py-3 text-base font-medium border border-transparent rounded-md bg-fac-yellow-600 text-neutral-800 hover:bg-fac-yellow-700 md:py-4 md:px-10 md:text-lg"
                                >
                                    Find Now
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </main>
            <Benefits></Benefits>
            <CTA setOpen={setOpen}></CTA>
            <Footer></Footer>
            <WaitingListModal open={open} setOpen={setOpen} submit={submitReview}></WaitingListModal>
        </div>
    )
}



const features = [
    {
        name: 'Trusted Care',
        description:
            'We only work with trusted and licensed Childcare facilities. You can be sure your child is in safe hands.',
        icon: StarIcon,
    },
    {
        name: 'Save time',
        description:
            'We understand how challenging it is to find flexible childcare, with Find A Creche you can search and book effortlessly.',
        icon: LockClosedIcon,
    },
    {
        name: 'Communication',
        description:
            'Our team will get in touch directly with relevant childcare options.',
        icon: ClockIcon,
    },
    {
        name: 'Flexible Childcare',
        description:
            'We understand in the current climate that parents availability can differ from week to week. Book only the days you need with no requirement for a weekly commitment.',
        icon: CurrencyPoundIcon,
    },
]

function Benefits() {
    return (
        <div className="py-24 bg-fac-blue-500 sm:py-32 lg:py-40">
            <div className="px-6 mx-auto max-w-7xl lg:px-8">
                <div className="sm:text-center">
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl">Benefits of joining Find A Creche</p>

                </div>

                <div className="max-w-lg mt-20 sm:mx-auto md:max-w-none">
                    <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row">
                                <div className="flex items-center justify-center w-12 h-12 text-white rounded-xl bg-fac-yellow-500 sm:shrink-0">
                                    <feature.icon className="w-8 h-8" aria-hidden="true" />
                                </div>
                                <div className="sm:min-w-0 sm:flex-1">
                                    <p className="text-lg font-semibold leading-8 text-gray-50">{feature.name}</p>
                                    <p className="mt-2 text-base leading-7 text-gray-100">{feature.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

function CTA({ setOpen }) {
    return (
        <div className="relative bg-indigo-700">
            <img
                className="absolute inset-0 object-cover w-full h-full"
                src={bg2}
            />
            <div className="relative z-10 max-w-5xl px-4 py-16 mx-auto text-center sm:py-44 sm:px-6 lg:px-8">
                <h2 className="text-6xl font-bold tracking-tight text-white sm:text-6xl">
                    <span className="block">We are on a mission to help Childcare facilities promote availability</span>
                </h2>
                <div onClick={() => setOpen(true)}
                    className="inline-flex items-center justify-center w-full px-5 py-3 mt-8 text-base font-medium text-indigo-600 bg-white border border-transparent rounded-md hover:bg-indigo-50 sm:w-auto"
                >
                    Get in touch
                </div>
            </div>
        </div>
    )
}


function WaitingListModal({ open, setOpen, submit }) {

    const [values, setValues] = useState({});

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Join Our Waiting List
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Name <span className="text-red-500">*</span>
                                            </label>
                                            <div className="w-full mt-1">
                                                <input
                                                    value={values.name}
                                                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                                                    type="text"
                                                    className="block w-full border-gray-300 rounded-md shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Email <span className="text-red-500">*</span>
                                            </label>
                                            <div className="w-full mt-1">
                                                <input
                                                    value={values.email}
                                                    onChange={(e) => setValues({ ...values, email: e.target.value })}
                                                    type="text"
                                                    className="block w-full border-gray-300 rounded-md shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Location
                                            </label>
                                            <div className="w-full mt-1">
                                                <input
                                                    value={values.location}
                                                    onChange={(e) => setValues({ ...values, location: e.target.value })}
                                                    type="text"
                                                    className="block w-full border-gray-300 rounded-md shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Details or Thoughts
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <textarea
                                                    value={values.details}
                                                    onChange={(e) => setValues({ ...values, details: e.target.value })}
                                                    rows={3}
                                                    className="block w-full border-gray-300 rounded-md shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        disabled={!values.name || !values.email}
                                        type="button"
                                        className="flex justify-center px-4 py-2 mx-auto text-base font-medium text-white border border-transparent rounded-md shadow-sm disabled:opacity-30 disabled:pointer-events-none bg-fac-blue-600 hover:bg-fac-blue-700 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2 sm:text-sm"
                                        onClick={() => { submit(values); setValues({}) }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}