import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    Bars3CenterLeftIcon,
    BellIcon,
    ClockIcon,
    CogIcon,
    CreditCardIcon,
    DocumentChartBarIcon,
    HomeIcon,
    QuestionMarkCircleIcon,
    ScaleIcon,
    ShieldCheckIcon,
    UserGroupIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import {
    ArrowDownIcon,
    BanknotesIcon,
    BuildingOfficeIcon,
    CalendarDaysIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    CurrencyPoundIcon,
    InboxIcon,
    MagnifyingGlassIcon,
    UserCircleIcon,

} from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import { api } from './api.service'
import SessionManage from './session-manage'


const navigation = [
    { name: 'Admin', href: '/creche-manage', icon: CogIcon, current: true },
    { name: 'Find A Creche Home', href: '/', icon: HomeIcon, current: false }
]
const secondaryNavigation = [
    { name: 'Logout', href: '/logout', icon: ArrowDownIcon }
]

const statusStyles = {
    success: 'bg-green-100 text-green-800',
    processing: 'bg-yellow-100 text-yellow-800',
    failed: 'bg-gray-100 text-gray-800',
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const getDateString = (d) => {
    return `${new Date(d).getFullYear()}-${new Date(d).getMonth() + 1}-${new Date(d).getDate()}`
}

export default function Admin() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [id, setId] = useState(null)

    const [booking, setBooking] = useState(null)
    const [session, setSession] = useState(null);
    const [viewModel, setViewModel] = useState({ creches: [], users: [], sessions: [] });
    const [waitlist, setWaitlist] = useState([]);

    useEffect(() => {

        api(`${process.env.REACT_APP_API_URL}/admin`).then(x => {
            setViewModel(x);
        });

    }, [])
    
    useEffect(() => {

        api(`${process.env.REACT_APP_API_URL}/admin/waitlist`).then(x => {
            setWaitlist(x.waitlist);
        });

    }, [])

    return (
        <div className="min-h-full">
            <SessionModal open={open} setOpen={setOpen} id={id}></SessionModal>
            <BookingModal open={!!booking} setOpen={() => { setBooking(null); setSession(null) }} booking={booking} session={session}></BookingModal>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-fac-blue-400 pt-5 pb-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <nav
                                    className="mt-5 h-full flex-shrink-0 divide-y divide-fac-blue-500 overflow-y-auto"
                                    aria-label="Sidebar"
                                >
                                    <div className="space-y-1 px-2">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-fac-blue-500 text-white'
                                                        : 'text-fac-blue-100 hover:text-white hover:bg-fac-blue-600',
                                                    'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-fac-blue-200" aria-hidden="true" />
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="mt-6 pt-6">
                                        <div className="space-y-1 px-2">
                                            {secondaryNavigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-fac-blue-100 hover:bg-fac-blue-600 hover:text-white"
                                                >
                                                    <item.icon className="mr-4 h-6 w-6 text-fac-blue-200" aria-hidden="true" />
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </nav>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-grow flex-col overflow-y-auto bg-fac-blue-400 pt-5 pb-4">
                    <nav className="mt-5 flex flex-1 flex-col divide-y divide-fac-blue-500 overflow-y-auto" aria-label="Sidebar">
                        <div className="space-y-1 px-2">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                        item.current ? 'bg-fac-blue-500 text-white' : 'text-fac-blue-100 hover:text-white hover:bg-fac-blue-600',
                                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-fac-blue-200" aria-hidden="true" />
                                    {item.name}
                                </a>
                            ))}
                        </div>
                        <div className="mt-6 pt-6">
                            <div className="space-y-1 px-2">
                                {secondaryNavigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-fac-blue-100 hover:bg-fac-blue-600 hover:text-white"
                                    >
                                        <item.icon className="mr-4 h-6 w-6 text-fac-blue-200" aria-hidden="true" />
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

            <div className="flex flex-1 flex-col lg:pl-64">
                <div className="flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none">
                    <button
                        type="button"
                        className="border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-fac-blue-500 lg:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    {/* Search bar */}
                    <div className="flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                        <div className="flex flex-1">

                        </div>
                        <div className="ml-4 flex items-center md:ml-6">


                        </div>
                    </div>
                </div>
                <main className="flex-1 pb-8">
                    {/* Page header */}
                    <div className="bg-white shadow">
                        <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                            <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                                <div className="min-w-0 flex-1">
                                    {/* Profile */}
                                    <div className="flex items-center">

                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8">

                        <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                            Creches
                        </h2>

                        {/* Table (small breakpoint and up) */}
                        <div className="hidden sm:block">
                            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                                <div className="mt-2 flex flex-col">
                                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Name
                                                    </th>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Postcode
                                                    </th>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Published
                                                    </th>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Contact Email
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {viewModel.creches.map((creche) => (
                                                    <tr key={creche._id} className="bg-white">
                                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            {creche.name}
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            {creche.postcode}
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            {creche.published ? 'Yes' : 'No'}
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            {creche.contactEmail}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8">

                        <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                            Waiting List
                        </h2>

                        {/* Table (small breakpoint and up) */}
                        <div className="hidden sm:block">
                            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                                <div className="mt-2 flex flex-col">
                                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Name
                                                    </th>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Email
                                                    </th>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Location
                                                    </th>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Notes
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {waitlist.map((x) => (
                                                    <tr key={x._id} className="bg-white">
                                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            {x.name}
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            {x.email}
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            {x.location}
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            {x.details}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8">

                        <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                            Users
                        </h2>

                        {/* Table (small breakpoint and up) */}
                        <div className="hidden sm:block">
                            <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                                <div className="mt-2 flex flex-col">
                                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Email
                                                    </th>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Name
                                                    </th>
                                                    <th
                                                        className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                        scope="col"
                                                    >
                                                        Type
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {viewModel.users.map((user) => (
                                                    <tr key={user._id} className="bg-white">
                                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            {user.email}
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            {user.name}
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            {user.type}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}


function SessionModal({ open, setOpen, id }) {

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                                <SessionManage id={id} close={() => setOpen(false)}></SessionManage>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function BookingModal({ open, setOpen, session, booking }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                                <div className="text-gray-800 text-2xl mb-5">{session?.title}</div>
                                <div className="text-gray-600 text-lg">Childs Name:</div>
                                <div className="text-gray-700 text-xl mb-3">{booking?.details?.childsName}</div>
                                <div className="text-gray-600 text-lg">Parent Notes: </div>
                                <div className="text-gray-700 text-xl mb-3">{booking?.details?.notes}</div>
                                <div className="text-gray-600 text-lg">Contact Details: </div>
                                <div className="text-gray-700 text-xl mb-3">{booking?.details?.contactDetails}</div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        type="button"
                                        className="flex mx-auto justify-center rounded-md border border-transparent bg-fac-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-fac-blue-700 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2 sm:text-sm"
                                        onClick={() => setOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}