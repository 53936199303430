import { Routes, Route } from "react-router-dom";
import './App.css';
import Layout from "./layout";
import Login from "./login";
import Register from "./register";
import Logout from "./logout";
import LandingPage from "./landing";
import RequestReset from "./request-reset";
import ResetPassword from "./reset-password";
import Creches from "./creches";
import CrecheManage from "./creche-manage";
import CrecheSettings from "./creche-settings";
import Creche from "./creche";
import Account from "./account";
import Success from "./success";
import Admin from "./admin";
import CrechesJobs from "./creches-jobs";
import CrecheJobs from "./creche-jobs";
import CrecheMessages from "./creche-messages";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/creches" element={<Creches />} />
      <Route path="/jobs" element={<CrechesJobs />} />
      <Route path="/job/:id" element={<CrecheJobs />} />
      <Route path="/creche-manage" element={<CrecheManage />} />
      <Route path="/creche-settings" element={<CrecheSettings />} />
      <Route path="/creche-messages" element={<CrecheMessages />} />
      <Route path="/creche/:id" element={<Creche />} />
      <Route path="/bookings" element={<Account />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/request-reset" element={<RequestReset />} />
      <Route path="/passwordReset" element={<ResetPassword />} />
      <Route path="/success/:id" element={<Success />} />
      <Route path="/admin" element={<Admin />} />
    </Routes>
  )
}
