import { Link } from "react-router-dom";

export default function Footer() {

    const token = localStorage.getItem("token")
    const userType = JSON.parse(localStorage.getItem("user"))?.type;

    return (
        <footer className="bg-white">
            <div className="px-4 py-12 mx-auto overflow-hidden max-w-7xl sm:px-6 lg:px-8">
                <nav className="flex flex-wrap justify-center -mx-5 -my-2" aria-label="Footer">
                    <div className="px-5 py-2">
                        <Link to="/" className="text-base text-gray-500 hover:text-gray-900">
                            Home
                        </Link>
                    </div>
                    <div className="px-5 py-2">
                        <Link to="/creches" className="text-base text-gray-500 hover:text-gray-900">
                            Creches
                        </Link>
                    </div>
                    {userType == 0 && <div className="px-5 py-2">
                        <Link to="/bookings" className="text-base text-gray-500 hover:text-gray-900">
                            My Bookings
                        </Link>
                    </div>}
                    {userType == 1 && <div className="px-5 py-2">
                        <Link to="/creche-manage" className="text-base text-gray-500 hover:text-gray-900">
                            Manage Creche
                        </Link>
                    </div>}
                    {token && <div className="px-5 py-2">
                        <Link to="/logout" className="text-base text-gray-500 hover:text-gray-900">
                            Logout
                        </Link>
                    </div>}
                    {!token && <div className="px-5 py-2">
                        <Link to="/login" className="text-base text-gray-500 hover:text-gray-900">
                            Login
                        </Link>
                    </div>}
                </nav>
                <p className="mt-8 text-base text-center text-gray-400">&copy; 2023 Find A Creche, Inc. All rights reserved. <a className="underline" href="mailto:info@findacreche.com">info@findacreche.com</a></p>
            </div>
        </footer>
    )
}
