import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    Bars3CenterLeftIcon,
    ChatBubbleBottomCenterIcon,
    CogIcon,
    HomeIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import {
    ArrowDownIcon,

} from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import { api } from './api.service'


const navigation = [
    { name: 'Dashboard', href: '/creche-manage', icon: HomeIcon, current: false },
    { name: 'Settings', href: '/creche-settings', icon: CogIcon, current: false },
    { name: 'Messages', href: '/creche-messages', icon: ChatBubbleBottomCenterIcon, current: true }
]
const secondaryNavigation = [
    { name: 'Logout', href: '/logout', icon: ArrowDownIcon }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CrecheMessages() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const navigate = useNavigate();

    const [messages, setMessages] = useState([]);
    const [messageId, setMessageId] = useState([]);
    const [value, setValue] = useState([]);

    useEffect(() => {

        api(`${process.env.REACT_APP_API_URL}/creche/messages`).then(x => {
            setMessages(x.messages)
        });

    }, [])

    const sendMessage = () => {
        api(`${process.env.REACT_APP_API_URL}/creche/message/${message.id}`, { message: value }).then(x => {
            setValue('')
            api(`${process.env.REACT_APP_API_URL}/creche/messages`).then(x => {
                setMessages(x.messages)
            });
        });
    }

    let userIds = messages?.map(x => x.user?._id).filter((val, i, arr) => arr.findIndex(x => x == val) == i)
    let threads = userIds.map(x => { return { id: x, name: messages.find(y => y.user?._id == x)?.user?.name, messages: messages.filter(y => y.user?._id == x) } })
    let message = threads?.find(x => x.id == messageId)

    return (
        <div className="min-h-screen flex">
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-fac-blue-400 pt-5 pb-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <nav
                                    className="mt-5 h-full flex-shrink-0 divide-y divide-fac-blue-500 overflow-y-auto"
                                    aria-label="Sidebar"
                                >
                                    <div className="space-y-1 px-2">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-fac-blue-500 text-white'
                                                        : 'text-fac-blue-100 hover:text-white hover:bg-fac-blue-600',
                                                    'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-fac-blue-200" aria-hidden="true" />
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                    <div className="mt-6 pt-6">
                                        <div className="space-y-1 px-2">
                                            {secondaryNavigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-fac-blue-100 hover:bg-fac-blue-600 hover:text-white"
                                                >
                                                    <item.icon className="mr-4 h-6 w-6 text-fac-blue-200" aria-hidden="true" />
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </nav>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-grow flex-col overflow-y-auto bg-fac-blue-400 pt-5 pb-4">
                    <nav className="mt-5 flex flex-1 flex-col divide-y divide-fac-blue-500 overflow-y-auto" aria-label="Sidebar">
                        <div className="space-y-1 px-2">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                        item.current ? 'bg-fac-blue-500 text-white' : 'text-fac-blue-100 hover:text-white hover:bg-fac-blue-600',
                                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-fac-blue-200" aria-hidden="true" />
                                    {item.name}
                                </a>
                            ))}
                        </div>
                        <div className="mt-6 pt-6">
                            <div className="space-y-1 px-2">
                                {secondaryNavigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-fac-blue-100 hover:bg-fac-blue-600 hover:text-white"
                                    >
                                        <item.icon className="mr-4 h-6 w-6 text-fac-blue-200" aria-hidden="true" />
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

            <div className="flex flex-1 flex-col lg:pl-64">
                <div className="flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none">
                    <button
                        type="button"
                        className="border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-fac-blue-500 lg:hidden"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    {/* Search bar */}
                    <div className="flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                        <div className="flex flex-1">

                        </div>
                        <div className="ml-4 flex items-center md:ml-6">


                        </div>
                    </div>
                </div>
                <main className="min-w-0 flex-1 border-t border-gray-200 xl:flex">
                    <section
                        aria-labelledby="message-heading"
                        className="flex h-full min-w-0 flex-1 flex-col overflow-hidden xl:order-last"
                    >
                        {message && <div className="min-h-0 flex-1 overflow-y-auto">
                            <div className="bg-white pb-6 pt-5 shadow">
                                <div className="px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-8">
                                    <div className="sm:w-0 sm:flex-1">
                                        <h1 id="message-heading" className="text-lg font-medium text-gray-900">
                                            {message.name}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            {/* Thread section*/}
                            <ul role="list" className="space-y-2 py-4 sm:space-y-4 sm:px-6 lg:px-8">
                                {message.messages?.map((item) => (
                                    <li key={item._id} className="bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6">
                                        <div className="sm:flex sm:items-baseline sm:justify-between">
                                            <h3 className="text-base font-medium">
                                                <span className="text-gray-900">{item.sentByCreche ? 'You' : message.name}</span>{' '}
                                            </h3>
                                            <p className="mt-1 whitespace-nowrap text-sm text-gray-600 sm:ml-3 sm:mt-0">
                                                <span>{new Date(item.date).toDateString()}</span>
                                            </p>
                                        </div>
                                        <div
                                            className="mt-4 space-y-6 text-sm text-gray-800"
                                        >{item.message}</div>
                                    </li>
                                ))}
                                <div className="flex border-t p-3 align-baseline">
                                    <textarea rows="3" className="flex-1 rounded-lg shadow h-20 p-1" value={value} onChange={(e) => setValue(e.target.value)}></textarea>

                                    <div className="flex flex-col justify-end">
                                        <div className="w-20 text-center bg-blue-500 text-white rounded ml-5 cursor-pointer py-2" onClick={() => sendMessage()}>Send</div>
                                    </div>
                                </div>
                            </ul>
                        </div>}
                    </section>

                    {/* Message list*/}
                    <aside className="hidden xl:order-first xl:block xl:flex-shrink-0">
                        <div className="relative flex h-full w-96 flex-col border-r border-gray-200 bg-gray-100">
                            <div className="flex-shrink-0">
                                <div className="flex h-16 flex-col justify-center bg-white px-6">
                                    <div className="flex items-baseline space-x-3">
                                        <h2 className="text-lg font-medium text-gray-900">Messages</h2>
                                    </div>
                                </div>
                            </div>
                            <nav aria-label="Message list" className="min-h-0 flex-1 overflow-y-auto">
                                <ul role="list" className="divide-y divide-gray-200 border-b border-gray-200">
                                    {threads?.map((message) => (
                                        <li onClick={() => setMessageId(message.id)}
                                            key={message.name}
                                            className="relative bg-white px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 hover:bg-gray-50"
                                        >
                                            <div className="flex justify-between space-x-3">
                                                <div className="min-w-0 flex-1">
                                                    <a href={message.href} className="block focus:outline-none">
                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                        <p className="truncate text-sm font-medium text-gray-900">{message.name}</p>
                                                    </a>
                                                </div>
                                                <span
                                                    className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500"
                                                >
                                                    {new Date(message?.messages?.reverse()[0]?.date).toDateString()}
                                                </span>
                                            </div>
                                            <div className="mt-1">
                                                <p className="line-clamp-2 text-sm text-gray-600">{message?.messages[0]?.message}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </aside>
                </main>
            </div>
        </div>
    )
}