import { useEffect, useState } from "react"
import { api, api_delete } from "./api.service";

export default function SessionManage({ id, close }) {

    const [values, setValues] = useState({})

    useEffect(() => {
        if (id)
            api(`${process.env.REACT_APP_API_URL}/session/${id}`).then(x => {
                x.date = `${new Date(x.date).getFullYear()}-${new Date(x.date).getMonth() + 1}-${new Date(x.date).getDate()}`
                setValues(x)
            });

    }, [id])

    const submit = () => {
        api(`${process.env.REACT_APP_API_URL}/session`, { session: values }).then(x => {
            close();
        });
    }

    const deleteClick = () => {
        api_delete(`${process.env.REACT_APP_API_URL}/session/${id}`).then(x => {
            close();
        });
    }

    return (
        <form className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div className="space-y-6 sm:space-y-5">
                    <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Session</h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                            Manage an available booking slot
                        </p>
                    </div>

                    <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Session Title
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={values.title}
                                    onChange={(e) => setValues({ ...values, title: e.target.value })}
                                    type="text"
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Time Range
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={values.timeRange}
                                    onChange={(e) => setValues({ ...values, timeRange: e.target.value })}
                                    type="text"
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Age
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <input
                                    value={values.age}
                                    onChange={(e) => setValues({ ...values, age: e.target.value })}
                                    type="text"
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:max-w-xs sm:text-sm"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Session Notes
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                <textarea
                                    value={values.notes}
                                    onChange={(e) => setValues({ ...values, notes: e.target.value })}
                                    rows={3}
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                />
                                <p className="mt-2 text-sm text-gray-500">Any notes or useful information about this session</p>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Price
                            </label>
                            <div className="mt-1 sm:col-span-1 sm:mt-0">
                                <input
                                    value={values.price}
                                    onChange={(e) => setValues({ ...values, price: e.target.value })}
                                    type="number"
                                    min="0.00" step="0.01"
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                />
                            </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Session Date
                            </label>
                            <div className="mt-1 sm:col-span-1 sm:mt-0">
                                <input
                                    value={values.date}
                                    onChange={(e) => setValues({ ...values, date: e.target.value })}
                                    type="date"
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                />
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div className="pt-5 flex">
                <div className="flex-1 justify-start">
                    <div className="flex justify-start">
                        {id && <div onClick={() => deleteClick()}
                            className="cursor-pointer rounded-md border border-red-300 bg-red-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2"
                        >
                            Delete
                        </div>}
                    </div>

                </div>
                <div className="flex justify-end">
                    <div onClick={() => close()}
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2"
                    >
                        Cancel
                    </div>
                    <div
                        onClick={() => submit()}
                        className="cursor-pointer ml-3 inline-flex justify-center rounded-md border border-transparent bg-fac-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-fac-blue-700 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2"
                    >
                        Save
                    </div>
                </div>
            </div>
        </form>
    )
}
