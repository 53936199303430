import { useEffect, useState } from "react";
import { api } from "./api.service";
import Header from "./header"
import { IKImage, IKContext, IKUpload } from 'imagekitio-react'
import { Link, useParams } from "react-router-dom";
import { StarIcon } from '@heroicons/react/20/solid'
import { RadioGroup } from '@headlessui/react'
import { CalendarIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import Footer from "./footer";

export default function CrecheJobs() {

    const [creche, setCreche] = useState({});
    const params = useParams();

    useEffect(() => {
        api(`${process.env.REACT_APP_API_URL}/public/creche/${params.id}`).then(x => {
            setCreche(x.creche)
        });
    }, [])

    const reload = () => {
        api(`${process.env.REACT_APP_API_URL}/public/creche/${params.id}`).then(x => {
            setCreche(x.creche)
        });
    }

    return (<>
        <Header></Header>
        <Product reload={reload} creche={creche}></Product>
        <Footer></Footer>
    </>

    )
}

function Product({ creche, reload }) {
    const [sessionId, setSessionId] = useState(null)

    const bookSession = (id, data) => {
        setSessionId(null)

        api(`${process.env.REACT_APP_API_URL}/application/${id}`, { data: data }).then(x => {
            reload();
        });
    }

    const openSession = (id) => {
        api(`${process.env.REACT_APP_API_URL}/public/creche/${creche._id}`).then(x => {
            setSessionId(id)
        });
    }

    return (
        <div className="bg-white">
            {!!sessionId && <PurchaseModal open={!!sessionId} setOpen={() => setSessionId(null)} id={sessionId} sessionData={creche?.jobs?.find(x => x._id == sessionId)} book={(id, data) => bookSession(id, data)}></PurchaseModal>}
            <div className="pt-6">

                <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-1 lg:gap-x-8 lg:px-8">
                    <div className="hidden overflow-hidden rounded-lg lg:block">
                        <IKContext
                            urlEndpoint="https://ik.imagekit.io/verticode">
                            <IKImage className="h-full w-full object-cover object-center" path={`${creche.image_id}`} />
                        </IKContext>
                    </div>
                </div>

                <div className="mx-auto max-w-2xl px-4 pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-4 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-24">
                    <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{creche.name}</h1>
                    </div>

                    <div className="mt-4 lg:row-span-3 lg:col-span-2 lg:mt-0">
                        <p className="text-3xl tracking-tight text-gray-900 mb-5">Upcoming Jobs</p>
                        <List sessions={creche.jobs} openSession={(id) => { openSession(id); }}></List>
                    </div>

                    <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pt-6 lg:pb-16 lg:pr-8">
                        <div>
                            <div className="space-y-6">
                                <p className="text-base text-gray-900 mb-3">{creche.about}</p>
                                <p className="text-base text-gray-900"><b>Age Range - </b>{creche.age}</p>
                                <p className="text-base text-gray-900"><b>Phone - </b>{creche.contact}</p>
                                <p className="text-base text-gray-900"><b>Email - </b>{creche.contactEmail}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

function List({ sessions, openSession }) {
    console.log(sessions)
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return (
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
                {sessions?.filter(x => (new Date(x.date) >= today)).sort(function (a, b) {
                    return new Date(a.date) - new Date(b.date);
                }).map((session) => (
                    <li key={session._id}>
                        <span onClick={() => openSession(session._id)} className="block hover:bg-gray-50">
                            <div className="flex items-center px-4 py-4 sm:px-6">
                                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                    <div className="truncate">
                                        <div className="flex text-sm">
                                            <p className="truncate font-medium text-fac-blue-600">{session.title}</p>
                                        </div>
                                        <div className="mt-2 flex">
                                            <div className="flex items-center text-sm text-gray-500">
                                                <CalendarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                                <p>
                                                    {session.date ? session.date.split('T')[0] : ''}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ml-5 flex-shrink-0">
                                    <span>Apply Now</span>
                                    <ChevronRightIcon className="h-5 w-5 inline text-gray-400" aria-hidden="true" />
                                </div>
                            </div>
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    )
}

function PurchaseModal({ open, setOpen, id, sessionData, book }) {

    const [values, setValues] = useState({});

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                            Apply for this Job
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {sessionData.title}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                {sessionData.date ? sessionData.date.split('T')[0] : ''} {sessionData.timeRange}
                                            </p>
                                            <p className="text-sm text-gray-500">
                                                {sessionData.notes}
                                            </p>
                                        </div>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Enter your information below.
                                            </p>
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Name <span className="text-red-500">*</span>
                                            </label>
                                            <div className="mt-1 w-full">
                                                <input
                                                    value={values.name}
                                                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                                                    type="text"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Contact Details <span className="text-red-500">*</span>
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <textarea
                                                    value={values.contactDetails}
                                                    onChange={(e) => setValues({ ...values, contactDetails: e.target.value })}
                                                    rows={3}
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-2">
                                            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                                Application Details
                                            </label>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <textarea
                                                    value={values.details}
                                                    onChange={(e) => setValues({ ...values, details: e.target.value })}
                                                    rows={3}
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-fac-blue-500 focus:ring-fac-blue-500 sm:text-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        disabled={!values.name || !values.contactDetails}
                                        type="button"
                                        className="disabled:opacity-30 disabled:pointer-events-none flex mx-auto justify-center rounded-md border border-transparent bg-fac-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-fac-blue-700 focus:outline-none focus:ring-2 focus:ring-fac-blue-500 focus:ring-offset-2 sm:text-sm"
                                        onClick={() => book(id, values)}
                                    >
                                        Apply Now
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
